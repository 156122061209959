<template>
  <v-container fluid>
    <p class="font-weight-bold">Wording List</p>
    <v-data-table
      :loading="isLoading"
      :headers="displayedHeader"
      :items="items"
      class="elevation-1"
      item-key="id"
      @click:row="rowClicked"
      :server-items-length="wordingListTotalEntry"
      :options.sync="pagination"
      :footer-props="{showCurrentPage: true, showFirstLastPage: true, 'items-per-page-options': $_item_per_page}"
      hide-default-footer
    >
      <template v-slot:[`item.actions`]>
        <v-btn
          @click:row="rowClicked"
          color="primary"
          small
          v-if="userAccess.canUpdate"
        >
          {{$_strings.common.EDIT}}
        </v-btn>
      </template>
      <template v-slot:[`item.parameterCode`]=items>
        <span>{{ items.item.parameterCode }}</span>
      </template>
      <template v-slot:[`item.parameterName`]=items>
        <span>{{ items.item.parameterName }}</span>
      </template>
    </v-data-table>
  </v-container>
</template>
<script>
export default {
  name: 'wording-page',
  data() {
    return {
      isLoading: false,
      searchText: '',
      headers: [
        {
          text: this.$_strings.parameter.headers.PARAMETER_NAME,
          value: 'parameterCode',
          sortable: false,
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.parameter.headers.DESCRIPTION,
          value: 'parameterName',
          sortable: false,
          cellClass: 'clickable',
        },
        {
          value: 'actions',
          sortable: false,
        },
      ],
      pagination: {
        itemsPerPage: 10,
        page: 1,
        sortBy: [],
        sortDesc: [],
      },
      wordingListTotalEntry: 0,
      items: [],
    };
  },
  created() {
    this.fetchData();
  },
  computed: {
    displayedHeader() {
      return this.headers.map((i) => ({
        ...i,
        class: 'white--text primary',
      }));
    },
  },
  watch: {
    pagination: {
      handler() {
        this.fetchData();
      },
      deep: true,
    },
  },
  methods: {
    fetchData() {
      const {
        page, itemsPerPage, sortBy, sortDesc,
      } = this.pagination;
      const filters = {
        page: page - 1,
        size: itemsPerPage,
        name: this.searchText,
        sort: '',
      };
      if (sortBy.length > 0) {
        const isDesc = sortDesc[0] ? 'DESC' : 'ASC';
        filters.sort = `${sortBy[0]},${isDesc}`;
      }
      this.isLoading = true;
      this.$_services.wording.fetchWordingList(filters)
        .then((result) => {
          this.items = result.data.contents;
          this.wordingListTotalEntry = result.data.totalData;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    rowClicked(e, { item }) {
      this.$router.push({
        name: 'edit-wording',
        params: { wordingId: item.id },
      });
    },
  },
};
</script>
